.testimonials-page {
  .testimonial-more {
    font-style: normal;
    font-size: 0.8em;
    cursor: pointer;
  }

  .testimonial-bottom {
    font-size: 0.9em;
    font-weight: bold;
  }

  .testimonial {
    width: 97%;
    padding: 10px;
    margin: 15px 0 10px 0;
    background-color: #f7f9fa;
    border: 1px solid #e5e5e5;
    font-style: italic;
    h3 {
      margin-top: 0px;
    }
  }
}
